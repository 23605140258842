import React from "react"
import { graphql, StaticQuery } from "gatsby"
import get from "lodash/get"
import Grid from "../components/Grid"
import Wrapper from "../components/Wrapper"
import CollectionTeaser from "../components/CollectionTeaser"
import ParseSEOData from "../components/ParseSEOData"
import { defaultLang } from "../../prismic-configuration"

export const shopQuery = graphql`
  query {
    prismic {
      allShops {
        edges {
          node {
            meta_description
            meta_title
            share_image
            heading
            _meta {
              uid
              type
              lang
            }
          }
        }
      }
      allCollections(sortBy: rank_ASC, first: 150) {
        edges {
          node {
            title
            heading
            from_price
            featured_detail_image
            featured_image_portrait
            _meta {
              uid
              type
              lang
            }
          }
        }
      }
    }
  }
`

const Shop = ({ data }) => {
  const doc = data.prismic.allShops.edges.find(pageData => {
    return pageData.node._meta.lang === defaultLang;
  })

  const heading = get(doc, "node.heading[0].text")
  return (
    <>
      <ParseSEOData data={doc.node} />
      <div className="shop">
        {heading && (
          <header class="mb--xxl">
            <div class="samples-header-inner">
              <h1 class="fs--xl lh--s">{heading}</h1>
            </div>
          </header>
        )}
        <Wrapper>
          <Grid>
            <StaticQuery
              query={shopQuery}
              render={data => {
                let collectionsData = data.prismic.allCollections.edges
                collectionsData = collectionsData.filter(collectionData => {
                  // Filter out kontrakt collections
                  const collectionHeading = collectionData.node.heading[0].text.toLowerCase();
                  return (
                    collectionData.node._meta.lang === defaultLang &&
                    (!collectionHeading.includes('kontrakt') && !collectionHeading.includes('contract')));
                })

                return collectionsData.map((collection, index) => {
                  const node = get(collection, "node")
                  const title = get(node, "title[0].text")
                  const heading = get(node, "heading[0].text")
                  const prismicImage = get(node, "featured_image_portrait")
                  const detailPrismicImage = get(node, "featured_detail_image")
                  const meta = get(node, "_meta")
                  const price = get(node, "from_price")

                  return (
                    <CollectionTeaser
                      key={index}
                      title={title}
                      heading={heading}
                      prismicImage={prismicImage}
                      detailPrismicImage={detailPrismicImage}
                      meta={meta}
                      price={price}
                    />
                  )
                })
              }}
            />
          </Grid>
        </Wrapper>
      </div>
    </>
  )
}

Shop.query = shopQuery

export default Shop
